<template>
  <div>
    <e-form ref="refForm" :config="formConfig" :column="formColumn" :data.sync="detailData" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      formConfig: {},
      formColumn: [
        {
          type: 'date',
          title: '采购日期',
          field: 'xxx',
          required: true,
          disabled: false
        },
        {
          type: 'text',
          title: '采购单号',
          field: 'xxx',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'text',
          title: '供应商',
          field: 'xxx',
          required: true,
          disabled: false
        },
        {
          type: 'text',
          title: '是否开票',
          field: 'xxx',
          disabled: false
        },
        {
          type: 'text',
          title: '付款方式',
          field: 'xxx',
          disabled: false
        },
        {
          type: 'text',
          title: '交货日期',
          field: 'xxx',
          disabled: false
        },
        {
          type: 'text',
          title: '提货方式',
          field: 'xxx',
          disabled: false
        },
        {
          type: 'text',
          title: '需求部门',
          field: 'xxx',
          disabled: false
        },
        {
          type: 'text',
          title: '采购方式',
          field: 'xxx',
          disabled: false
        }
      ],
      detailData: {}
    }
  }
}
</script>

<style lang="less" scoped></style>
