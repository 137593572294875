<template>
  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane v-for="(item, index) in navList" :key="index" :label="item.key" :name="item.val" />
  </el-tabs>
</template>

<script>
export default {
  data() {
    return {
      activeName: '0',
      navList: [
        {
          key: '采购列表',
          val: '0',
          path: ''
        },
        {
          key: '生产列表',
          val: '1',
          path: ''
        }
      ]
    }
  },
  methods: {
    handleClick() {}
  }
}
</script>

<style lang="less" scoped></style>
